import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import DemoPage from "../../../components/ColoradoPortedComps/DemoPage";
import LocalSlider from "../../../components/ColoradoPortedComps/LocalSlider";
// import PartnerLogos from "../../../components/ColoradoPortedComps/PartnerLogos";
import {
  coloradoTestimonialsData,
  coloradoLogos,
} from "../../../data/local/colorado-data";
import ogImage from "../../../images/global_assets/og-image.png";

const PartnerLogos = loadable(() =>
  import("../../../components/ColoradoPortedComps/PartnerLogosTemplate")
);

const Demo = () => {
  return (
    <Layout isLanding phoneNumber="+8005640174" phoneText="(800) 654-0174">
      <SEO
        title="SpotOn Colorado| Restaurant Point Of Sale"
        description="SpotOn Colorado makes it easier than ever for on-the-go food businesses like food trucks to take orders, print tickets, process payments, and stay online just about anywhere."
        image={`https://spoton.com/${ogImage}`}
      />
      <DemoPage
        formId="4af595c6-8118-431a-8669-64ece3861048"
        thankYouPath="/local/colorado/thank-you"
      />
      <section style={{ marginTop: 40 }}>
        <PartnerLogos logos={coloradoLogos} />
      </section>
      <LocalSlider testimonialsData={coloradoTestimonialsData} />
    </Layout>
  );
};

export default Demo;
